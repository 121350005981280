<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M7.412 18.435c.382.382.886.565 1.374.565.489 0 .993-.183 1.374-.565L21.275 7.321c.763-.764.763-2 0-2.748-.764-.764-2-.764-2.748 0l-9.74 9.74-3.466-3.466c-.764-.763-2-.763-2.748 0-.764.764-.764 2 0 2.748l4.84 4.84Z"
      fill="#33888C"
    />
  </svg>
</template>
